.EngagementScreen {
  padding: 60px;

  .inner {
    max-width: 58em;
    width: 90%;
    min-height: 100vh;
    padding: 5rem 7rem;
    background: #fff;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1),
      0 10px 30px #f3ece8;
    border-radius: 0.2em 0.2em 0 0;
    margin: auto;
    margin-top: 0.3125em;
    margin-bottom: 0;

    .title {
      text-align: center;
      margin: 0;
      margin-bottom: 30px;
      border-bottom: 3px solid #283c46;
      padding-bottom: 0.625em;
    }
  }

  .engagement-list {
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin: 20px 0;
    cursor: pointer;
    border-radius: 0.6rem;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.35), 0 1px 3px rgba(0, 0, 0, 0.1);
    background: #fff;
  }

  .card:first-child {
    margin-top: 0;
  }

  .card__details {
    display: flex;
    flex-direction: column;
    width: 40%;
  }

  .card__title {
    h2 {
      display: inline;
      padding-right: 20px;
      font-weight: bold;
    }
  }

  .card__meta {
    .published-mark {
      margin: 0;
      font-size: 1.4rem;
      padding: 3px 0;
      color: #848484;

      span {
        font-weight: bold;
      }
    }
  }

  .card__steps {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }

  .card__step-icon {
    height: 2rem;
    margin-right: 10px;
    cursor: pointer;
    opacity: 0.6;
  }

  .card__stats {
    display: flex;
    flex-direction: row;
    flex: 0 0 440px;
  }

  .card__stat {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding-right: 36px;

    p {
      margin: 0;
    }
  }

  .card__stat-number {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .card__stat-meta {
    font-size: 1.4rem;

    color: #848484;
  }

  .card__actions {
    position: absolute;
    right: 10px;
    top: 3px;
  }

  .tag--published {
    color: #283c46;
    font-weight: 400;
  }

  .btn-overflow {
    background-image: url(./svg/overflow--light.svg);
    width: 2.45em;
    height: 2.45em;
    padding: 0 !important;
    background-position: center center !important;
    background-repeat: no-repeat;
    background-size: 2rem auto;
    text-indent: -999em;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    white-space: normal;
    cursor: pointer;
    font-size: 1.3rem;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    line-height: 1.5;
    outline: none;
    text-decoration: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box;
    border: none;
  }

  .card-drawer-option {
    padding: 1rem 1.5rem;

    a {
      color: inherit;
    }

    i {
      padding-right: 10px;
    }
  }
}
