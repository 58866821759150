.login-screen {
  display: flex;
  flex-direction: column;
  flex: 1;

  align-items: center;
  min-height: 100%;

  .brand-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 45px auto 20px auto;
    width: 100%;
    max-width: 988px;
    padding: 20px 0;

    .logo {
      height: 75px;
      margin-bottom: 15px;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      background: #fff;
      padding: 40px;
      border-radius: 10px;
      width: 100%;
      max-width: 448px;
      box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1),
        0 10px 30px #f3ece8;

      h1 {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        margin: 0 0 15px;
        text-align: center;
      }

      .error--login {
        margin: 5px 0;
        text-align: center;
        font-size: 1.6rem;
      }
    }
  }
}

.input--text {
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  padding: 10px;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: box-shadow 0.15s ease-in-out;
  -moz-transition: box-shadow 0.15s ease-in-out;
  -ms-transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  text-decoration: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: 16px;
  color: #000;
  display: inline-block;
  line-height: inherit;
  white-space: normal;
  vertical-align: middle;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.txt--red {
  color: #d4442e;
}

.btn-action {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  padding: 10px 15px;
  border: 1px solid #bfbfbf;
  border-color: rgba(0, 0, 0, 0.15);
  border-bottom-width: 2px;
  border-radius: 4px;
  background-color: #fff;
  text-decoration: none;
  text-align: center;
  font-family: inherit;
  font-weight: inherit;
  font-weight: bold;
  font-size: 17px;
  color: #000;
  line-height: inherit;
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: box-shadow 0.15s ease-in-out;
  -moz-transition: box-shadow 0.15s ease-in-out;
  -ms-transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-action--login {
  background: #3db371;
  color: #fff;
  outline: none;
}

.push_half--bottom {
  margin-bottom: 0.625em !important;
}
