.CustomerScreen {
  padding: 60px;

  .inner {
    max-width: 58em;
    width: 90%;
    min-height: 100vh;
    /* padding: 5rem 7rem;*/
    padding: 5rem 0;
    background: #fff;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1),
      0 10px 30px #f3ece8;
    border-radius: 0.2em 0.2em 0 0;
    margin: auto;
    margin-top: 0.3125em;
    margin-bottom: 0;
  }

  .title {
    text-align: center;
  }

  .ant-table-thead > tr > th {
    color: #283c46;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    color: inherit;
  }

  .ant-table-tbody > tr {
    cursor: pointer;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background: #f9f9fa;
  }

  .ant-table-tbody > tr > td {
    border: 0;
  }
}
